import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _29d3d5d3 = () => interopDefault(import('../src/pages/404.vue' /* webpackChunkName: "pages/404" */))
const _083388e8 = () => interopDefault(import('../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _7421ab4b = () => interopDefault(import('../src/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _277f61ef = () => interopDefault(import('../src/pages/changelog.vue' /* webpackChunkName: "pages/changelog" */))
const _802afe8a = () => interopDefault(import('../src/pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _05d7db95 = () => interopDefault(import('../src/pages/checkout-processing.vue' /* webpackChunkName: "pages/checkout-processing" */))
const _27eb63ad = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _48c7d6b8 = () => interopDefault(import('../src/pages/donate.vue' /* webpackChunkName: "pages/donate" */))
const _aa52ea06 = () => interopDefault(import('../src/pages/download.vue' /* webpackChunkName: "pages/download" */))
const _2660fbe1 = () => interopDefault(import('../src/pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _231dcea3 = () => interopDefault(import('../src/pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _173dce19 = () => interopDefault(import('../src/pages/security-and-compliance.vue' /* webpackChunkName: "pages/security-and-compliance" */))
const _8046c324 = () => interopDefault(import('../src/pages/stripe-processing.vue' /* webpackChunkName: "pages/stripe-processing" */))
const _5c2dbc3c = () => interopDefault(import('../src/pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _515e5e2c = () => interopDefault(import('../src/pages/thankyou.vue' /* webpackChunkName: "pages/thankyou" */))
const _fccb8c68 = () => interopDefault(import('../src/pages/unit-converter.vue' /* webpackChunkName: "pages/unit-converter" */))
const _ab2d74b4 = () => interopDefault(import('../src/pages/account/activity.vue' /* webpackChunkName: "pages/account/activity" */))
const _56b24807 = () => interopDefault(import('../src/pages/account/api-tokens/index.vue' /* webpackChunkName: "pages/account/api-tokens/index" */))
const _eac5375c = () => interopDefault(import('../src/pages/account/billing-plan.vue' /* webpackChunkName: "pages/account/billing-plan" */))
const _49ec4b5e = () => interopDefault(import('../src/pages/account/change-password.vue' /* webpackChunkName: "pages/account/change-password" */))
const _d8f7e062 = () => interopDefault(import('../src/pages/account/faq.vue' /* webpackChunkName: "pages/account/faq" */))
const _1c47ba33 = () => interopDefault(import('../src/pages/account/health.vue' /* webpackChunkName: "pages/account/health" */))
const _1235b1fd = () => interopDefault(import('../src/pages/account/invoices.vue' /* webpackChunkName: "pages/account/invoices" */))
const _1c42a8a0 = () => interopDefault(import('../src/pages/account/jobs/index.vue' /* webpackChunkName: "pages/account/jobs/index" */))
const _5ebaab61 = () => interopDefault(import('../src/pages/account/notifications.vue' /* webpackChunkName: "pages/account/notifications" */))
const _c1c0a6bc = () => interopDefault(import('../src/pages/account/payment-method/index.vue' /* webpackChunkName: "pages/account/payment-method/index" */))
const _f5bf9120 = () => interopDefault(import('../src/pages/account/presets/index.vue' /* webpackChunkName: "pages/account/presets/index" */))
const _17bf654a = () => interopDefault(import('../src/pages/account/tasks/index.vue' /* webpackChunkName: "pages/account/tasks/index" */))
const _23471612 = () => interopDefault(import('../src/pages/account/team/index.vue' /* webpackChunkName: "pages/account/team/index" */))
const _44417d94 = () => interopDefault(import('../src/pages/account/webhooks/index.vue' /* webpackChunkName: "pages/account/webhooks/index" */))
const _a67c760e = () => interopDefault(import('../src/pages/auth/admin-login.vue' /* webpackChunkName: "pages/auth/admin-login" */))
const _439c33cf = () => interopDefault(import('../src/pages/auth/forget.vue' /* webpackChunkName: "pages/auth/forget" */))
const _6b3a8677 = () => interopDefault(import('../src/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _4870357a = () => interopDefault(import('../src/pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _7ef68946 = () => interopDefault(import('../src/pages/account/payment-method/change-card.vue' /* webpackChunkName: "pages/account/payment-method/change-card" */))
const _5b7b1201 = () => interopDefault(import('../src/pages/auth/free/password-set.vue' /* webpackChunkName: "pages/auth/free/password-set" */))
const _2fb570ae = () => interopDefault(import('../src/pages/auth/free/signup.vue' /* webpackChunkName: "pages/auth/free/signup" */))
const _5b628dc0 = () => interopDefault(import('../src/pages/account/team/join/_id.vue' /* webpackChunkName: "pages/account/team/join/_id" */))
const _d8f84bd0 = () => interopDefault(import('../src/pages/account/jobs/_id.vue' /* webpackChunkName: "pages/account/jobs/_id" */))
const _34cb9b9c = () => interopDefault(import('../src/pages/account/tasks/_id.vue' /* webpackChunkName: "pages/account/tasks/_id" */))
const _2523a888 = () => interopDefault(import('../src/pages/auth/reset/_id.vue' /* webpackChunkName: "pages/auth/reset/_id" */))
const _070f19bc = () => interopDefault(import('../src/pages/convert/_id/index.vue' /* webpackChunkName: "pages/convert/_id/index" */))
const _342c8426 = () => interopDefault(import('../src/pages/time/_id.vue' /* webpackChunkName: "pages/time/_id" */))
const _9620c4b8 = () => interopDefault(import('../src/pages/unit/_id.vue' /* webpackChunkName: "pages/unit/_id" */))
const _b0d432e4 = () => interopDefault(import('../src/pages/convert/_id/download.vue' /* webpackChunkName: "pages/convert/_id/download" */))
const _2de0e018 = () => interopDefault(import('../src/pages/_id/index.vue' /* webpackChunkName: "pages/_id/index" */))
const _1d5032b2 = () => interopDefault(import('../src/pages/_id/download.vue' /* webpackChunkName: "pages/_id/download" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _29d3d5d3,
    name: "404___en"
  }, {
    path: "/about",
    component: _083388e8,
    name: "about___en"
  }, {
    path: "/account",
    component: _7421ab4b,
    name: "account___en"
  }, {
    path: "/changelog",
    component: _277f61ef,
    name: "changelog___en"
  }, {
    path: "/checkout",
    component: _802afe8a,
    name: "checkout___en"
  }, {
    path: "/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___en"
  }, {
    path: "/de",
    component: _27eb63ad,
    name: "index___de"
  }, {
    path: "/donate",
    component: _48c7d6b8,
    name: "donate___en"
  }, {
    path: "/download",
    component: _aa52ea06,
    name: "download___en"
  }, {
    path: "/en-br",
    component: _27eb63ad,
    name: "index___en-br"
  }, {
    path: "/en-id",
    component: _27eb63ad,
    name: "index___en-id"
  }, {
    path: "/en-in",
    component: _27eb63ad,
    name: "index___en-in"
  }, {
    path: "/en-my",
    component: _27eb63ad,
    name: "index___en-my"
  }, {
    path: "/en-ph",
    component: _27eb63ad,
    name: "index___en-ph"
  }, {
    path: "/es",
    component: _27eb63ad,
    name: "index___es"
  }, {
    path: "/fr",
    component: _27eb63ad,
    name: "index___fr"
  }, {
    path: "/id",
    component: _27eb63ad,
    name: "index___id"
  }, {
    path: "/it",
    component: _27eb63ad,
    name: "index___it"
  }, {
    path: "/ja",
    component: _27eb63ad,
    name: "index___ja"
  }, {
    path: "/ko",
    component: _27eb63ad,
    name: "index___ko"
  }, {
    path: "/nl",
    component: _27eb63ad,
    name: "index___nl"
  }, {
    path: "/pl",
    component: _27eb63ad,
    name: "index___pl"
  }, {
    path: "/pricing",
    component: _2660fbe1,
    name: "pricing___en"
  }, {
    path: "/privacy",
    component: _231dcea3,
    name: "privacy___en"
  }, {
    path: "/pt",
    component: _27eb63ad,
    name: "index___pt"
  }, {
    path: "/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___en"
  }, {
    path: "/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___en"
  }, {
    path: "/sv",
    component: _27eb63ad,
    name: "index___sv"
  }, {
    path: "/terms",
    component: _5c2dbc3c,
    name: "terms___en"
  }, {
    path: "/thankyou",
    component: _515e5e2c,
    name: "thankyou___en"
  }, {
    path: "/tr",
    component: _27eb63ad,
    name: "index___tr"
  }, {
    path: "/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___en"
  }, {
    path: "/zh",
    component: _27eb63ad,
    name: "index___zh"
  }, {
    path: "/account/activity",
    component: _ab2d74b4,
    name: "account-activity___en"
  }, {
    path: "/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___en"
  }, {
    path: "/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___en"
  }, {
    path: "/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___en"
  }, {
    path: "/account/faq",
    component: _d8f7e062,
    name: "account-faq___en"
  }, {
    path: "/account/health",
    component: _1c47ba33,
    name: "account-health___en"
  }, {
    path: "/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___en"
  }, {
    path: "/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___en"
  }, {
    path: "/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___en"
  }, {
    path: "/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___en"
  }, {
    path: "/account/presets",
    component: _f5bf9120,
    name: "account-presets___en"
  }, {
    path: "/account/tasks",
    component: _17bf654a,
    name: "account-tasks___en"
  }, {
    path: "/account/team",
    component: _23471612,
    name: "account-team___en"
  }, {
    path: "/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___en"
  }, {
    path: "/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___en"
  }, {
    path: "/auth/forget",
    component: _439c33cf,
    name: "auth-forget___en"
  }, {
    path: "/auth/login",
    component: _6b3a8677,
    name: "auth-login___en"
  }, {
    path: "/auth/signup",
    component: _4870357a,
    name: "auth-signup___en"
  }, {
    path: "/de/404",
    component: _29d3d5d3,
    name: "404___de"
  }, {
    path: "/de/about",
    component: _083388e8,
    name: "about___de"
  }, {
    path: "/de/account",
    component: _7421ab4b,
    name: "account___de"
  }, {
    path: "/de/changelog",
    component: _277f61ef,
    name: "changelog___de"
  }, {
    path: "/de/checkout",
    component: _802afe8a,
    name: "checkout___de"
  }, {
    path: "/de/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___de"
  }, {
    path: "/de/donate",
    component: _48c7d6b8,
    name: "donate___de"
  }, {
    path: "/de/download",
    component: _aa52ea06,
    name: "download___de"
  }, {
    path: "/de/pricing",
    component: _2660fbe1,
    name: "pricing___de"
  }, {
    path: "/de/privacy",
    component: _231dcea3,
    name: "privacy___de"
  }, {
    path: "/de/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___de"
  }, {
    path: "/de/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___de"
  }, {
    path: "/de/terms",
    component: _5c2dbc3c,
    name: "terms___de"
  }, {
    path: "/de/thankyou",
    component: _515e5e2c,
    name: "thankyou___de"
  }, {
    path: "/de/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___de"
  }, {
    path: "/en-br/404",
    component: _29d3d5d3,
    name: "404___en-br"
  }, {
    path: "/en-br/about",
    component: _083388e8,
    name: "about___en-br"
  }, {
    path: "/en-br/account",
    component: _7421ab4b,
    name: "account___en-br"
  }, {
    path: "/en-br/changelog",
    component: _277f61ef,
    name: "changelog___en-br"
  }, {
    path: "/en-br/checkout",
    component: _802afe8a,
    name: "checkout___en-br"
  }, {
    path: "/en-br/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___en-br"
  }, {
    path: "/en-br/donate",
    component: _48c7d6b8,
    name: "donate___en-br"
  }, {
    path: "/en-br/download",
    component: _aa52ea06,
    name: "download___en-br"
  }, {
    path: "/en-br/pricing",
    component: _2660fbe1,
    name: "pricing___en-br"
  }, {
    path: "/en-br/privacy",
    component: _231dcea3,
    name: "privacy___en-br"
  }, {
    path: "/en-br/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___en-br"
  }, {
    path: "/en-br/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___en-br"
  }, {
    path: "/en-br/terms",
    component: _5c2dbc3c,
    name: "terms___en-br"
  }, {
    path: "/en-br/thankyou",
    component: _515e5e2c,
    name: "thankyou___en-br"
  }, {
    path: "/en-br/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___en-br"
  }, {
    path: "/en-id/404",
    component: _29d3d5d3,
    name: "404___en-id"
  }, {
    path: "/en-id/about",
    component: _083388e8,
    name: "about___en-id"
  }, {
    path: "/en-id/account",
    component: _7421ab4b,
    name: "account___en-id"
  }, {
    path: "/en-id/changelog",
    component: _277f61ef,
    name: "changelog___en-id"
  }, {
    path: "/en-id/checkout",
    component: _802afe8a,
    name: "checkout___en-id"
  }, {
    path: "/en-id/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___en-id"
  }, {
    path: "/en-id/donate",
    component: _48c7d6b8,
    name: "donate___en-id"
  }, {
    path: "/en-id/download",
    component: _aa52ea06,
    name: "download___en-id"
  }, {
    path: "/en-id/pricing",
    component: _2660fbe1,
    name: "pricing___en-id"
  }, {
    path: "/en-id/privacy",
    component: _231dcea3,
    name: "privacy___en-id"
  }, {
    path: "/en-id/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___en-id"
  }, {
    path: "/en-id/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___en-id"
  }, {
    path: "/en-id/terms",
    component: _5c2dbc3c,
    name: "terms___en-id"
  }, {
    path: "/en-id/thankyou",
    component: _515e5e2c,
    name: "thankyou___en-id"
  }, {
    path: "/en-id/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___en-id"
  }, {
    path: "/en-in/404",
    component: _29d3d5d3,
    name: "404___en-in"
  }, {
    path: "/en-in/about",
    component: _083388e8,
    name: "about___en-in"
  }, {
    path: "/en-in/account",
    component: _7421ab4b,
    name: "account___en-in"
  }, {
    path: "/en-in/changelog",
    component: _277f61ef,
    name: "changelog___en-in"
  }, {
    path: "/en-in/checkout",
    component: _802afe8a,
    name: "checkout___en-in"
  }, {
    path: "/en-in/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___en-in"
  }, {
    path: "/en-in/donate",
    component: _48c7d6b8,
    name: "donate___en-in"
  }, {
    path: "/en-in/download",
    component: _aa52ea06,
    name: "download___en-in"
  }, {
    path: "/en-in/pricing",
    component: _2660fbe1,
    name: "pricing___en-in"
  }, {
    path: "/en-in/privacy",
    component: _231dcea3,
    name: "privacy___en-in"
  }, {
    path: "/en-in/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___en-in"
  }, {
    path: "/en-in/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___en-in"
  }, {
    path: "/en-in/terms",
    component: _5c2dbc3c,
    name: "terms___en-in"
  }, {
    path: "/en-in/thankyou",
    component: _515e5e2c,
    name: "thankyou___en-in"
  }, {
    path: "/en-in/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___en-in"
  }, {
    path: "/en-my/404",
    component: _29d3d5d3,
    name: "404___en-my"
  }, {
    path: "/en-my/about",
    component: _083388e8,
    name: "about___en-my"
  }, {
    path: "/en-my/account",
    component: _7421ab4b,
    name: "account___en-my"
  }, {
    path: "/en-my/changelog",
    component: _277f61ef,
    name: "changelog___en-my"
  }, {
    path: "/en-my/checkout",
    component: _802afe8a,
    name: "checkout___en-my"
  }, {
    path: "/en-my/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___en-my"
  }, {
    path: "/en-my/donate",
    component: _48c7d6b8,
    name: "donate___en-my"
  }, {
    path: "/en-my/download",
    component: _aa52ea06,
    name: "download___en-my"
  }, {
    path: "/en-my/pricing",
    component: _2660fbe1,
    name: "pricing___en-my"
  }, {
    path: "/en-my/privacy",
    component: _231dcea3,
    name: "privacy___en-my"
  }, {
    path: "/en-my/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___en-my"
  }, {
    path: "/en-my/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___en-my"
  }, {
    path: "/en-my/terms",
    component: _5c2dbc3c,
    name: "terms___en-my"
  }, {
    path: "/en-my/thankyou",
    component: _515e5e2c,
    name: "thankyou___en-my"
  }, {
    path: "/en-my/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___en-my"
  }, {
    path: "/en-ph/404",
    component: _29d3d5d3,
    name: "404___en-ph"
  }, {
    path: "/en-ph/about",
    component: _083388e8,
    name: "about___en-ph"
  }, {
    path: "/en-ph/account",
    component: _7421ab4b,
    name: "account___en-ph"
  }, {
    path: "/en-ph/changelog",
    component: _277f61ef,
    name: "changelog___en-ph"
  }, {
    path: "/en-ph/checkout",
    component: _802afe8a,
    name: "checkout___en-ph"
  }, {
    path: "/en-ph/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___en-ph"
  }, {
    path: "/en-ph/donate",
    component: _48c7d6b8,
    name: "donate___en-ph"
  }, {
    path: "/en-ph/download",
    component: _aa52ea06,
    name: "download___en-ph"
  }, {
    path: "/en-ph/pricing",
    component: _2660fbe1,
    name: "pricing___en-ph"
  }, {
    path: "/en-ph/privacy",
    component: _231dcea3,
    name: "privacy___en-ph"
  }, {
    path: "/en-ph/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___en-ph"
  }, {
    path: "/en-ph/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___en-ph"
  }, {
    path: "/en-ph/terms",
    component: _5c2dbc3c,
    name: "terms___en-ph"
  }, {
    path: "/en-ph/thankyou",
    component: _515e5e2c,
    name: "thankyou___en-ph"
  }, {
    path: "/en-ph/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___en-ph"
  }, {
    path: "/es/404",
    component: _29d3d5d3,
    name: "404___es"
  }, {
    path: "/es/about",
    component: _083388e8,
    name: "about___es"
  }, {
    path: "/es/account",
    component: _7421ab4b,
    name: "account___es"
  }, {
    path: "/es/changelog",
    component: _277f61ef,
    name: "changelog___es"
  }, {
    path: "/es/checkout",
    component: _802afe8a,
    name: "checkout___es"
  }, {
    path: "/es/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___es"
  }, {
    path: "/es/donate",
    component: _48c7d6b8,
    name: "donate___es"
  }, {
    path: "/es/download",
    component: _aa52ea06,
    name: "download___es"
  }, {
    path: "/es/pricing",
    component: _2660fbe1,
    name: "pricing___es"
  }, {
    path: "/es/privacy",
    component: _231dcea3,
    name: "privacy___es"
  }, {
    path: "/es/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___es"
  }, {
    path: "/es/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___es"
  }, {
    path: "/es/terms",
    component: _5c2dbc3c,
    name: "terms___es"
  }, {
    path: "/es/thankyou",
    component: _515e5e2c,
    name: "thankyou___es"
  }, {
    path: "/es/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___es"
  }, {
    path: "/fr/404",
    component: _29d3d5d3,
    name: "404___fr"
  }, {
    path: "/fr/about",
    component: _083388e8,
    name: "about___fr"
  }, {
    path: "/fr/account",
    component: _7421ab4b,
    name: "account___fr"
  }, {
    path: "/fr/changelog",
    component: _277f61ef,
    name: "changelog___fr"
  }, {
    path: "/fr/checkout",
    component: _802afe8a,
    name: "checkout___fr"
  }, {
    path: "/fr/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___fr"
  }, {
    path: "/fr/donate",
    component: _48c7d6b8,
    name: "donate___fr"
  }, {
    path: "/fr/download",
    component: _aa52ea06,
    name: "download___fr"
  }, {
    path: "/fr/pricing",
    component: _2660fbe1,
    name: "pricing___fr"
  }, {
    path: "/fr/privacy",
    component: _231dcea3,
    name: "privacy___fr"
  }, {
    path: "/fr/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___fr"
  }, {
    path: "/fr/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___fr"
  }, {
    path: "/fr/terms",
    component: _5c2dbc3c,
    name: "terms___fr"
  }, {
    path: "/fr/thankyou",
    component: _515e5e2c,
    name: "thankyou___fr"
  }, {
    path: "/fr/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___fr"
  }, {
    path: "/id/404",
    component: _29d3d5d3,
    name: "404___id"
  }, {
    path: "/id/about",
    component: _083388e8,
    name: "about___id"
  }, {
    path: "/id/account",
    component: _7421ab4b,
    name: "account___id"
  }, {
    path: "/id/changelog",
    component: _277f61ef,
    name: "changelog___id"
  }, {
    path: "/id/checkout",
    component: _802afe8a,
    name: "checkout___id"
  }, {
    path: "/id/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___id"
  }, {
    path: "/id/donate",
    component: _48c7d6b8,
    name: "donate___id"
  }, {
    path: "/id/download",
    component: _aa52ea06,
    name: "download___id"
  }, {
    path: "/id/pricing",
    component: _2660fbe1,
    name: "pricing___id"
  }, {
    path: "/id/privacy",
    component: _231dcea3,
    name: "privacy___id"
  }, {
    path: "/id/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___id"
  }, {
    path: "/id/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___id"
  }, {
    path: "/id/terms",
    component: _5c2dbc3c,
    name: "terms___id"
  }, {
    path: "/id/thankyou",
    component: _515e5e2c,
    name: "thankyou___id"
  }, {
    path: "/id/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___id"
  }, {
    path: "/it/404",
    component: _29d3d5d3,
    name: "404___it"
  }, {
    path: "/it/about",
    component: _083388e8,
    name: "about___it"
  }, {
    path: "/it/account",
    component: _7421ab4b,
    name: "account___it"
  }, {
    path: "/it/changelog",
    component: _277f61ef,
    name: "changelog___it"
  }, {
    path: "/it/checkout",
    component: _802afe8a,
    name: "checkout___it"
  }, {
    path: "/it/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___it"
  }, {
    path: "/it/donate",
    component: _48c7d6b8,
    name: "donate___it"
  }, {
    path: "/it/download",
    component: _aa52ea06,
    name: "download___it"
  }, {
    path: "/it/pricing",
    component: _2660fbe1,
    name: "pricing___it"
  }, {
    path: "/it/privacy",
    component: _231dcea3,
    name: "privacy___it"
  }, {
    path: "/it/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___it"
  }, {
    path: "/it/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___it"
  }, {
    path: "/it/terms",
    component: _5c2dbc3c,
    name: "terms___it"
  }, {
    path: "/it/thankyou",
    component: _515e5e2c,
    name: "thankyou___it"
  }, {
    path: "/it/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___it"
  }, {
    path: "/ja/404",
    component: _29d3d5d3,
    name: "404___ja"
  }, {
    path: "/ja/about",
    component: _083388e8,
    name: "about___ja"
  }, {
    path: "/ja/account",
    component: _7421ab4b,
    name: "account___ja"
  }, {
    path: "/ja/changelog",
    component: _277f61ef,
    name: "changelog___ja"
  }, {
    path: "/ja/checkout",
    component: _802afe8a,
    name: "checkout___ja"
  }, {
    path: "/ja/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___ja"
  }, {
    path: "/ja/donate",
    component: _48c7d6b8,
    name: "donate___ja"
  }, {
    path: "/ja/download",
    component: _aa52ea06,
    name: "download___ja"
  }, {
    path: "/ja/pricing",
    component: _2660fbe1,
    name: "pricing___ja"
  }, {
    path: "/ja/privacy",
    component: _231dcea3,
    name: "privacy___ja"
  }, {
    path: "/ja/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___ja"
  }, {
    path: "/ja/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___ja"
  }, {
    path: "/ja/terms",
    component: _5c2dbc3c,
    name: "terms___ja"
  }, {
    path: "/ja/thankyou",
    component: _515e5e2c,
    name: "thankyou___ja"
  }, {
    path: "/ja/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___ja"
  }, {
    path: "/ko/404",
    component: _29d3d5d3,
    name: "404___ko"
  }, {
    path: "/ko/about",
    component: _083388e8,
    name: "about___ko"
  }, {
    path: "/ko/account",
    component: _7421ab4b,
    name: "account___ko"
  }, {
    path: "/ko/changelog",
    component: _277f61ef,
    name: "changelog___ko"
  }, {
    path: "/ko/checkout",
    component: _802afe8a,
    name: "checkout___ko"
  }, {
    path: "/ko/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___ko"
  }, {
    path: "/ko/donate",
    component: _48c7d6b8,
    name: "donate___ko"
  }, {
    path: "/ko/download",
    component: _aa52ea06,
    name: "download___ko"
  }, {
    path: "/ko/pricing",
    component: _2660fbe1,
    name: "pricing___ko"
  }, {
    path: "/ko/privacy",
    component: _231dcea3,
    name: "privacy___ko"
  }, {
    path: "/ko/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___ko"
  }, {
    path: "/ko/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___ko"
  }, {
    path: "/ko/terms",
    component: _5c2dbc3c,
    name: "terms___ko"
  }, {
    path: "/ko/thankyou",
    component: _515e5e2c,
    name: "thankyou___ko"
  }, {
    path: "/ko/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___ko"
  }, {
    path: "/nl/404",
    component: _29d3d5d3,
    name: "404___nl"
  }, {
    path: "/nl/about",
    component: _083388e8,
    name: "about___nl"
  }, {
    path: "/nl/account",
    component: _7421ab4b,
    name: "account___nl"
  }, {
    path: "/nl/changelog",
    component: _277f61ef,
    name: "changelog___nl"
  }, {
    path: "/nl/checkout",
    component: _802afe8a,
    name: "checkout___nl"
  }, {
    path: "/nl/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___nl"
  }, {
    path: "/nl/donate",
    component: _48c7d6b8,
    name: "donate___nl"
  }, {
    path: "/nl/download",
    component: _aa52ea06,
    name: "download___nl"
  }, {
    path: "/nl/pricing",
    component: _2660fbe1,
    name: "pricing___nl"
  }, {
    path: "/nl/privacy",
    component: _231dcea3,
    name: "privacy___nl"
  }, {
    path: "/nl/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___nl"
  }, {
    path: "/nl/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___nl"
  }, {
    path: "/nl/terms",
    component: _5c2dbc3c,
    name: "terms___nl"
  }, {
    path: "/nl/thankyou",
    component: _515e5e2c,
    name: "thankyou___nl"
  }, {
    path: "/nl/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___nl"
  }, {
    path: "/pl/404",
    component: _29d3d5d3,
    name: "404___pl"
  }, {
    path: "/pl/about",
    component: _083388e8,
    name: "about___pl"
  }, {
    path: "/pl/account",
    component: _7421ab4b,
    name: "account___pl"
  }, {
    path: "/pl/changelog",
    component: _277f61ef,
    name: "changelog___pl"
  }, {
    path: "/pl/checkout",
    component: _802afe8a,
    name: "checkout___pl"
  }, {
    path: "/pl/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___pl"
  }, {
    path: "/pl/donate",
    component: _48c7d6b8,
    name: "donate___pl"
  }, {
    path: "/pl/download",
    component: _aa52ea06,
    name: "download___pl"
  }, {
    path: "/pl/pricing",
    component: _2660fbe1,
    name: "pricing___pl"
  }, {
    path: "/pl/privacy",
    component: _231dcea3,
    name: "privacy___pl"
  }, {
    path: "/pl/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___pl"
  }, {
    path: "/pl/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___pl"
  }, {
    path: "/pl/terms",
    component: _5c2dbc3c,
    name: "terms___pl"
  }, {
    path: "/pl/thankyou",
    component: _515e5e2c,
    name: "thankyou___pl"
  }, {
    path: "/pl/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___pl"
  }, {
    path: "/pt/404",
    component: _29d3d5d3,
    name: "404___pt"
  }, {
    path: "/pt/about",
    component: _083388e8,
    name: "about___pt"
  }, {
    path: "/pt/account",
    component: _7421ab4b,
    name: "account___pt"
  }, {
    path: "/pt/changelog",
    component: _277f61ef,
    name: "changelog___pt"
  }, {
    path: "/pt/checkout",
    component: _802afe8a,
    name: "checkout___pt"
  }, {
    path: "/pt/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___pt"
  }, {
    path: "/pt/donate",
    component: _48c7d6b8,
    name: "donate___pt"
  }, {
    path: "/pt/download",
    component: _aa52ea06,
    name: "download___pt"
  }, {
    path: "/pt/pricing",
    component: _2660fbe1,
    name: "pricing___pt"
  }, {
    path: "/pt/privacy",
    component: _231dcea3,
    name: "privacy___pt"
  }, {
    path: "/pt/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___pt"
  }, {
    path: "/pt/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___pt"
  }, {
    path: "/pt/terms",
    component: _5c2dbc3c,
    name: "terms___pt"
  }, {
    path: "/pt/thankyou",
    component: _515e5e2c,
    name: "thankyou___pt"
  }, {
    path: "/pt/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___pt"
  }, {
    path: "/sv/404",
    component: _29d3d5d3,
    name: "404___sv"
  }, {
    path: "/sv/about",
    component: _083388e8,
    name: "about___sv"
  }, {
    path: "/sv/account",
    component: _7421ab4b,
    name: "account___sv"
  }, {
    path: "/sv/changelog",
    component: _277f61ef,
    name: "changelog___sv"
  }, {
    path: "/sv/checkout",
    component: _802afe8a,
    name: "checkout___sv"
  }, {
    path: "/sv/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___sv"
  }, {
    path: "/sv/donate",
    component: _48c7d6b8,
    name: "donate___sv"
  }, {
    path: "/sv/download",
    component: _aa52ea06,
    name: "download___sv"
  }, {
    path: "/sv/pricing",
    component: _2660fbe1,
    name: "pricing___sv"
  }, {
    path: "/sv/privacy",
    component: _231dcea3,
    name: "privacy___sv"
  }, {
    path: "/sv/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___sv"
  }, {
    path: "/sv/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___sv"
  }, {
    path: "/sv/terms",
    component: _5c2dbc3c,
    name: "terms___sv"
  }, {
    path: "/sv/thankyou",
    component: _515e5e2c,
    name: "thankyou___sv"
  }, {
    path: "/sv/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___sv"
  }, {
    path: "/tr/404",
    component: _29d3d5d3,
    name: "404___tr"
  }, {
    path: "/tr/about",
    component: _083388e8,
    name: "about___tr"
  }, {
    path: "/tr/account",
    component: _7421ab4b,
    name: "account___tr"
  }, {
    path: "/tr/changelog",
    component: _277f61ef,
    name: "changelog___tr"
  }, {
    path: "/tr/checkout",
    component: _802afe8a,
    name: "checkout___tr"
  }, {
    path: "/tr/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___tr"
  }, {
    path: "/tr/donate",
    component: _48c7d6b8,
    name: "donate___tr"
  }, {
    path: "/tr/download",
    component: _aa52ea06,
    name: "download___tr"
  }, {
    path: "/tr/pricing",
    component: _2660fbe1,
    name: "pricing___tr"
  }, {
    path: "/tr/privacy",
    component: _231dcea3,
    name: "privacy___tr"
  }, {
    path: "/tr/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___tr"
  }, {
    path: "/tr/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___tr"
  }, {
    path: "/tr/terms",
    component: _5c2dbc3c,
    name: "terms___tr"
  }, {
    path: "/tr/thankyou",
    component: _515e5e2c,
    name: "thankyou___tr"
  }, {
    path: "/tr/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___tr"
  }, {
    path: "/zh/404",
    component: _29d3d5d3,
    name: "404___zh"
  }, {
    path: "/zh/about",
    component: _083388e8,
    name: "about___zh"
  }, {
    path: "/zh/account",
    component: _7421ab4b,
    name: "account___zh"
  }, {
    path: "/zh/changelog",
    component: _277f61ef,
    name: "changelog___zh"
  }, {
    path: "/zh/checkout",
    component: _802afe8a,
    name: "checkout___zh"
  }, {
    path: "/zh/checkout-processing",
    component: _05d7db95,
    name: "checkout-processing___zh"
  }, {
    path: "/zh/donate",
    component: _48c7d6b8,
    name: "donate___zh"
  }, {
    path: "/zh/download",
    component: _aa52ea06,
    name: "download___zh"
  }, {
    path: "/zh/pricing",
    component: _2660fbe1,
    name: "pricing___zh"
  }, {
    path: "/zh/privacy",
    component: _231dcea3,
    name: "privacy___zh"
  }, {
    path: "/zh/security-and-compliance",
    component: _173dce19,
    name: "security-and-compliance___zh"
  }, {
    path: "/zh/stripe-processing",
    component: _8046c324,
    name: "stripe-processing___zh"
  }, {
    path: "/zh/terms",
    component: _5c2dbc3c,
    name: "terms___zh"
  }, {
    path: "/zh/thankyou",
    component: _515e5e2c,
    name: "thankyou___zh"
  }, {
    path: "/zh/unit-converter",
    component: _fccb8c68,
    name: "unit-converter___zh"
  }, {
    path: "/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___en"
  }, {
    path: "/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___en"
  }, {
    path: "/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___en"
  }, {
    path: "/de/account/activity",
    component: _ab2d74b4,
    name: "account-activity___de"
  }, {
    path: "/de/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___de"
  }, {
    path: "/de/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___de"
  }, {
    path: "/de/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___de"
  }, {
    path: "/de/account/faq",
    component: _d8f7e062,
    name: "account-faq___de"
  }, {
    path: "/de/account/health",
    component: _1c47ba33,
    name: "account-health___de"
  }, {
    path: "/de/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___de"
  }, {
    path: "/de/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___de"
  }, {
    path: "/de/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___de"
  }, {
    path: "/de/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___de"
  }, {
    path: "/de/account/presets",
    component: _f5bf9120,
    name: "account-presets___de"
  }, {
    path: "/de/account/tasks",
    component: _17bf654a,
    name: "account-tasks___de"
  }, {
    path: "/de/account/team",
    component: _23471612,
    name: "account-team___de"
  }, {
    path: "/de/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___de"
  }, {
    path: "/de/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___de"
  }, {
    path: "/de/auth/forget",
    component: _439c33cf,
    name: "auth-forget___de"
  }, {
    path: "/de/auth/login",
    component: _6b3a8677,
    name: "auth-login___de"
  }, {
    path: "/de/auth/signup",
    component: _4870357a,
    name: "auth-signup___de"
  }, {
    path: "/en-br/account/activity",
    component: _ab2d74b4,
    name: "account-activity___en-br"
  }, {
    path: "/en-br/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___en-br"
  }, {
    path: "/en-br/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___en-br"
  }, {
    path: "/en-br/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___en-br"
  }, {
    path: "/en-br/account/faq",
    component: _d8f7e062,
    name: "account-faq___en-br"
  }, {
    path: "/en-br/account/health",
    component: _1c47ba33,
    name: "account-health___en-br"
  }, {
    path: "/en-br/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___en-br"
  }, {
    path: "/en-br/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___en-br"
  }, {
    path: "/en-br/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___en-br"
  }, {
    path: "/en-br/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___en-br"
  }, {
    path: "/en-br/account/presets",
    component: _f5bf9120,
    name: "account-presets___en-br"
  }, {
    path: "/en-br/account/tasks",
    component: _17bf654a,
    name: "account-tasks___en-br"
  }, {
    path: "/en-br/account/team",
    component: _23471612,
    name: "account-team___en-br"
  }, {
    path: "/en-br/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___en-br"
  }, {
    path: "/en-br/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___en-br"
  }, {
    path: "/en-br/auth/forget",
    component: _439c33cf,
    name: "auth-forget___en-br"
  }, {
    path: "/en-br/auth/login",
    component: _6b3a8677,
    name: "auth-login___en-br"
  }, {
    path: "/en-br/auth/signup",
    component: _4870357a,
    name: "auth-signup___en-br"
  }, {
    path: "/en-id/account/activity",
    component: _ab2d74b4,
    name: "account-activity___en-id"
  }, {
    path: "/en-id/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___en-id"
  }, {
    path: "/en-id/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___en-id"
  }, {
    path: "/en-id/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___en-id"
  }, {
    path: "/en-id/account/faq",
    component: _d8f7e062,
    name: "account-faq___en-id"
  }, {
    path: "/en-id/account/health",
    component: _1c47ba33,
    name: "account-health___en-id"
  }, {
    path: "/en-id/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___en-id"
  }, {
    path: "/en-id/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___en-id"
  }, {
    path: "/en-id/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___en-id"
  }, {
    path: "/en-id/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___en-id"
  }, {
    path: "/en-id/account/presets",
    component: _f5bf9120,
    name: "account-presets___en-id"
  }, {
    path: "/en-id/account/tasks",
    component: _17bf654a,
    name: "account-tasks___en-id"
  }, {
    path: "/en-id/account/team",
    component: _23471612,
    name: "account-team___en-id"
  }, {
    path: "/en-id/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___en-id"
  }, {
    path: "/en-id/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___en-id"
  }, {
    path: "/en-id/auth/forget",
    component: _439c33cf,
    name: "auth-forget___en-id"
  }, {
    path: "/en-id/auth/login",
    component: _6b3a8677,
    name: "auth-login___en-id"
  }, {
    path: "/en-id/auth/signup",
    component: _4870357a,
    name: "auth-signup___en-id"
  }, {
    path: "/en-in/account/activity",
    component: _ab2d74b4,
    name: "account-activity___en-in"
  }, {
    path: "/en-in/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___en-in"
  }, {
    path: "/en-in/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___en-in"
  }, {
    path: "/en-in/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___en-in"
  }, {
    path: "/en-in/account/faq",
    component: _d8f7e062,
    name: "account-faq___en-in"
  }, {
    path: "/en-in/account/health",
    component: _1c47ba33,
    name: "account-health___en-in"
  }, {
    path: "/en-in/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___en-in"
  }, {
    path: "/en-in/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___en-in"
  }, {
    path: "/en-in/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___en-in"
  }, {
    path: "/en-in/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___en-in"
  }, {
    path: "/en-in/account/presets",
    component: _f5bf9120,
    name: "account-presets___en-in"
  }, {
    path: "/en-in/account/tasks",
    component: _17bf654a,
    name: "account-tasks___en-in"
  }, {
    path: "/en-in/account/team",
    component: _23471612,
    name: "account-team___en-in"
  }, {
    path: "/en-in/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___en-in"
  }, {
    path: "/en-in/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___en-in"
  }, {
    path: "/en-in/auth/forget",
    component: _439c33cf,
    name: "auth-forget___en-in"
  }, {
    path: "/en-in/auth/login",
    component: _6b3a8677,
    name: "auth-login___en-in"
  }, {
    path: "/en-in/auth/signup",
    component: _4870357a,
    name: "auth-signup___en-in"
  }, {
    path: "/en-my/account/activity",
    component: _ab2d74b4,
    name: "account-activity___en-my"
  }, {
    path: "/en-my/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___en-my"
  }, {
    path: "/en-my/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___en-my"
  }, {
    path: "/en-my/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___en-my"
  }, {
    path: "/en-my/account/faq",
    component: _d8f7e062,
    name: "account-faq___en-my"
  }, {
    path: "/en-my/account/health",
    component: _1c47ba33,
    name: "account-health___en-my"
  }, {
    path: "/en-my/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___en-my"
  }, {
    path: "/en-my/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___en-my"
  }, {
    path: "/en-my/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___en-my"
  }, {
    path: "/en-my/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___en-my"
  }, {
    path: "/en-my/account/presets",
    component: _f5bf9120,
    name: "account-presets___en-my"
  }, {
    path: "/en-my/account/tasks",
    component: _17bf654a,
    name: "account-tasks___en-my"
  }, {
    path: "/en-my/account/team",
    component: _23471612,
    name: "account-team___en-my"
  }, {
    path: "/en-my/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___en-my"
  }, {
    path: "/en-my/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___en-my"
  }, {
    path: "/en-my/auth/forget",
    component: _439c33cf,
    name: "auth-forget___en-my"
  }, {
    path: "/en-my/auth/login",
    component: _6b3a8677,
    name: "auth-login___en-my"
  }, {
    path: "/en-my/auth/signup",
    component: _4870357a,
    name: "auth-signup___en-my"
  }, {
    path: "/en-ph/account/activity",
    component: _ab2d74b4,
    name: "account-activity___en-ph"
  }, {
    path: "/en-ph/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___en-ph"
  }, {
    path: "/en-ph/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___en-ph"
  }, {
    path: "/en-ph/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___en-ph"
  }, {
    path: "/en-ph/account/faq",
    component: _d8f7e062,
    name: "account-faq___en-ph"
  }, {
    path: "/en-ph/account/health",
    component: _1c47ba33,
    name: "account-health___en-ph"
  }, {
    path: "/en-ph/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___en-ph"
  }, {
    path: "/en-ph/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___en-ph"
  }, {
    path: "/en-ph/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___en-ph"
  }, {
    path: "/en-ph/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___en-ph"
  }, {
    path: "/en-ph/account/presets",
    component: _f5bf9120,
    name: "account-presets___en-ph"
  }, {
    path: "/en-ph/account/tasks",
    component: _17bf654a,
    name: "account-tasks___en-ph"
  }, {
    path: "/en-ph/account/team",
    component: _23471612,
    name: "account-team___en-ph"
  }, {
    path: "/en-ph/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___en-ph"
  }, {
    path: "/en-ph/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___en-ph"
  }, {
    path: "/en-ph/auth/forget",
    component: _439c33cf,
    name: "auth-forget___en-ph"
  }, {
    path: "/en-ph/auth/login",
    component: _6b3a8677,
    name: "auth-login___en-ph"
  }, {
    path: "/en-ph/auth/signup",
    component: _4870357a,
    name: "auth-signup___en-ph"
  }, {
    path: "/es/account/activity",
    component: _ab2d74b4,
    name: "account-activity___es"
  }, {
    path: "/es/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___es"
  }, {
    path: "/es/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___es"
  }, {
    path: "/es/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___es"
  }, {
    path: "/es/account/faq",
    component: _d8f7e062,
    name: "account-faq___es"
  }, {
    path: "/es/account/health",
    component: _1c47ba33,
    name: "account-health___es"
  }, {
    path: "/es/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___es"
  }, {
    path: "/es/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___es"
  }, {
    path: "/es/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___es"
  }, {
    path: "/es/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___es"
  }, {
    path: "/es/account/presets",
    component: _f5bf9120,
    name: "account-presets___es"
  }, {
    path: "/es/account/tasks",
    component: _17bf654a,
    name: "account-tasks___es"
  }, {
    path: "/es/account/team",
    component: _23471612,
    name: "account-team___es"
  }, {
    path: "/es/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___es"
  }, {
    path: "/es/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___es"
  }, {
    path: "/es/auth/forget",
    component: _439c33cf,
    name: "auth-forget___es"
  }, {
    path: "/es/auth/login",
    component: _6b3a8677,
    name: "auth-login___es"
  }, {
    path: "/es/auth/signup",
    component: _4870357a,
    name: "auth-signup___es"
  }, {
    path: "/fr/account/activity",
    component: _ab2d74b4,
    name: "account-activity___fr"
  }, {
    path: "/fr/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___fr"
  }, {
    path: "/fr/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___fr"
  }, {
    path: "/fr/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___fr"
  }, {
    path: "/fr/account/faq",
    component: _d8f7e062,
    name: "account-faq___fr"
  }, {
    path: "/fr/account/health",
    component: _1c47ba33,
    name: "account-health___fr"
  }, {
    path: "/fr/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___fr"
  }, {
    path: "/fr/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___fr"
  }, {
    path: "/fr/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___fr"
  }, {
    path: "/fr/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___fr"
  }, {
    path: "/fr/account/presets",
    component: _f5bf9120,
    name: "account-presets___fr"
  }, {
    path: "/fr/account/tasks",
    component: _17bf654a,
    name: "account-tasks___fr"
  }, {
    path: "/fr/account/team",
    component: _23471612,
    name: "account-team___fr"
  }, {
    path: "/fr/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___fr"
  }, {
    path: "/fr/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___fr"
  }, {
    path: "/fr/auth/forget",
    component: _439c33cf,
    name: "auth-forget___fr"
  }, {
    path: "/fr/auth/login",
    component: _6b3a8677,
    name: "auth-login___fr"
  }, {
    path: "/fr/auth/signup",
    component: _4870357a,
    name: "auth-signup___fr"
  }, {
    path: "/id/account/activity",
    component: _ab2d74b4,
    name: "account-activity___id"
  }, {
    path: "/id/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___id"
  }, {
    path: "/id/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___id"
  }, {
    path: "/id/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___id"
  }, {
    path: "/id/account/faq",
    component: _d8f7e062,
    name: "account-faq___id"
  }, {
    path: "/id/account/health",
    component: _1c47ba33,
    name: "account-health___id"
  }, {
    path: "/id/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___id"
  }, {
    path: "/id/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___id"
  }, {
    path: "/id/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___id"
  }, {
    path: "/id/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___id"
  }, {
    path: "/id/account/presets",
    component: _f5bf9120,
    name: "account-presets___id"
  }, {
    path: "/id/account/tasks",
    component: _17bf654a,
    name: "account-tasks___id"
  }, {
    path: "/id/account/team",
    component: _23471612,
    name: "account-team___id"
  }, {
    path: "/id/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___id"
  }, {
    path: "/id/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___id"
  }, {
    path: "/id/auth/forget",
    component: _439c33cf,
    name: "auth-forget___id"
  }, {
    path: "/id/auth/login",
    component: _6b3a8677,
    name: "auth-login___id"
  }, {
    path: "/id/auth/signup",
    component: _4870357a,
    name: "auth-signup___id"
  }, {
    path: "/it/account/activity",
    component: _ab2d74b4,
    name: "account-activity___it"
  }, {
    path: "/it/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___it"
  }, {
    path: "/it/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___it"
  }, {
    path: "/it/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___it"
  }, {
    path: "/it/account/faq",
    component: _d8f7e062,
    name: "account-faq___it"
  }, {
    path: "/it/account/health",
    component: _1c47ba33,
    name: "account-health___it"
  }, {
    path: "/it/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___it"
  }, {
    path: "/it/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___it"
  }, {
    path: "/it/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___it"
  }, {
    path: "/it/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___it"
  }, {
    path: "/it/account/presets",
    component: _f5bf9120,
    name: "account-presets___it"
  }, {
    path: "/it/account/tasks",
    component: _17bf654a,
    name: "account-tasks___it"
  }, {
    path: "/it/account/team",
    component: _23471612,
    name: "account-team___it"
  }, {
    path: "/it/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___it"
  }, {
    path: "/it/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___it"
  }, {
    path: "/it/auth/forget",
    component: _439c33cf,
    name: "auth-forget___it"
  }, {
    path: "/it/auth/login",
    component: _6b3a8677,
    name: "auth-login___it"
  }, {
    path: "/it/auth/signup",
    component: _4870357a,
    name: "auth-signup___it"
  }, {
    path: "/ja/account/activity",
    component: _ab2d74b4,
    name: "account-activity___ja"
  }, {
    path: "/ja/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___ja"
  }, {
    path: "/ja/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___ja"
  }, {
    path: "/ja/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___ja"
  }, {
    path: "/ja/account/faq",
    component: _d8f7e062,
    name: "account-faq___ja"
  }, {
    path: "/ja/account/health",
    component: _1c47ba33,
    name: "account-health___ja"
  }, {
    path: "/ja/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___ja"
  }, {
    path: "/ja/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___ja"
  }, {
    path: "/ja/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___ja"
  }, {
    path: "/ja/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___ja"
  }, {
    path: "/ja/account/presets",
    component: _f5bf9120,
    name: "account-presets___ja"
  }, {
    path: "/ja/account/tasks",
    component: _17bf654a,
    name: "account-tasks___ja"
  }, {
    path: "/ja/account/team",
    component: _23471612,
    name: "account-team___ja"
  }, {
    path: "/ja/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___ja"
  }, {
    path: "/ja/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___ja"
  }, {
    path: "/ja/auth/forget",
    component: _439c33cf,
    name: "auth-forget___ja"
  }, {
    path: "/ja/auth/login",
    component: _6b3a8677,
    name: "auth-login___ja"
  }, {
    path: "/ja/auth/signup",
    component: _4870357a,
    name: "auth-signup___ja"
  }, {
    path: "/ko/account/activity",
    component: _ab2d74b4,
    name: "account-activity___ko"
  }, {
    path: "/ko/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___ko"
  }, {
    path: "/ko/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___ko"
  }, {
    path: "/ko/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___ko"
  }, {
    path: "/ko/account/faq",
    component: _d8f7e062,
    name: "account-faq___ko"
  }, {
    path: "/ko/account/health",
    component: _1c47ba33,
    name: "account-health___ko"
  }, {
    path: "/ko/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___ko"
  }, {
    path: "/ko/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___ko"
  }, {
    path: "/ko/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___ko"
  }, {
    path: "/ko/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___ko"
  }, {
    path: "/ko/account/presets",
    component: _f5bf9120,
    name: "account-presets___ko"
  }, {
    path: "/ko/account/tasks",
    component: _17bf654a,
    name: "account-tasks___ko"
  }, {
    path: "/ko/account/team",
    component: _23471612,
    name: "account-team___ko"
  }, {
    path: "/ko/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___ko"
  }, {
    path: "/ko/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___ko"
  }, {
    path: "/ko/auth/forget",
    component: _439c33cf,
    name: "auth-forget___ko"
  }, {
    path: "/ko/auth/login",
    component: _6b3a8677,
    name: "auth-login___ko"
  }, {
    path: "/ko/auth/signup",
    component: _4870357a,
    name: "auth-signup___ko"
  }, {
    path: "/nl/account/activity",
    component: _ab2d74b4,
    name: "account-activity___nl"
  }, {
    path: "/nl/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___nl"
  }, {
    path: "/nl/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___nl"
  }, {
    path: "/nl/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___nl"
  }, {
    path: "/nl/account/faq",
    component: _d8f7e062,
    name: "account-faq___nl"
  }, {
    path: "/nl/account/health",
    component: _1c47ba33,
    name: "account-health___nl"
  }, {
    path: "/nl/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___nl"
  }, {
    path: "/nl/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___nl"
  }, {
    path: "/nl/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___nl"
  }, {
    path: "/nl/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___nl"
  }, {
    path: "/nl/account/presets",
    component: _f5bf9120,
    name: "account-presets___nl"
  }, {
    path: "/nl/account/tasks",
    component: _17bf654a,
    name: "account-tasks___nl"
  }, {
    path: "/nl/account/team",
    component: _23471612,
    name: "account-team___nl"
  }, {
    path: "/nl/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___nl"
  }, {
    path: "/nl/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___nl"
  }, {
    path: "/nl/auth/forget",
    component: _439c33cf,
    name: "auth-forget___nl"
  }, {
    path: "/nl/auth/login",
    component: _6b3a8677,
    name: "auth-login___nl"
  }, {
    path: "/nl/auth/signup",
    component: _4870357a,
    name: "auth-signup___nl"
  }, {
    path: "/pl/account/activity",
    component: _ab2d74b4,
    name: "account-activity___pl"
  }, {
    path: "/pl/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___pl"
  }, {
    path: "/pl/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___pl"
  }, {
    path: "/pl/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___pl"
  }, {
    path: "/pl/account/faq",
    component: _d8f7e062,
    name: "account-faq___pl"
  }, {
    path: "/pl/account/health",
    component: _1c47ba33,
    name: "account-health___pl"
  }, {
    path: "/pl/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___pl"
  }, {
    path: "/pl/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___pl"
  }, {
    path: "/pl/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___pl"
  }, {
    path: "/pl/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___pl"
  }, {
    path: "/pl/account/presets",
    component: _f5bf9120,
    name: "account-presets___pl"
  }, {
    path: "/pl/account/tasks",
    component: _17bf654a,
    name: "account-tasks___pl"
  }, {
    path: "/pl/account/team",
    component: _23471612,
    name: "account-team___pl"
  }, {
    path: "/pl/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___pl"
  }, {
    path: "/pl/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___pl"
  }, {
    path: "/pl/auth/forget",
    component: _439c33cf,
    name: "auth-forget___pl"
  }, {
    path: "/pl/auth/login",
    component: _6b3a8677,
    name: "auth-login___pl"
  }, {
    path: "/pl/auth/signup",
    component: _4870357a,
    name: "auth-signup___pl"
  }, {
    path: "/pt/account/activity",
    component: _ab2d74b4,
    name: "account-activity___pt"
  }, {
    path: "/pt/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___pt"
  }, {
    path: "/pt/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___pt"
  }, {
    path: "/pt/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___pt"
  }, {
    path: "/pt/account/faq",
    component: _d8f7e062,
    name: "account-faq___pt"
  }, {
    path: "/pt/account/health",
    component: _1c47ba33,
    name: "account-health___pt"
  }, {
    path: "/pt/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___pt"
  }, {
    path: "/pt/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___pt"
  }, {
    path: "/pt/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___pt"
  }, {
    path: "/pt/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___pt"
  }, {
    path: "/pt/account/presets",
    component: _f5bf9120,
    name: "account-presets___pt"
  }, {
    path: "/pt/account/tasks",
    component: _17bf654a,
    name: "account-tasks___pt"
  }, {
    path: "/pt/account/team",
    component: _23471612,
    name: "account-team___pt"
  }, {
    path: "/pt/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___pt"
  }, {
    path: "/pt/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___pt"
  }, {
    path: "/pt/auth/forget",
    component: _439c33cf,
    name: "auth-forget___pt"
  }, {
    path: "/pt/auth/login",
    component: _6b3a8677,
    name: "auth-login___pt"
  }, {
    path: "/pt/auth/signup",
    component: _4870357a,
    name: "auth-signup___pt"
  }, {
    path: "/sv/account/activity",
    component: _ab2d74b4,
    name: "account-activity___sv"
  }, {
    path: "/sv/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___sv"
  }, {
    path: "/sv/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___sv"
  }, {
    path: "/sv/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___sv"
  }, {
    path: "/sv/account/faq",
    component: _d8f7e062,
    name: "account-faq___sv"
  }, {
    path: "/sv/account/health",
    component: _1c47ba33,
    name: "account-health___sv"
  }, {
    path: "/sv/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___sv"
  }, {
    path: "/sv/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___sv"
  }, {
    path: "/sv/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___sv"
  }, {
    path: "/sv/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___sv"
  }, {
    path: "/sv/account/presets",
    component: _f5bf9120,
    name: "account-presets___sv"
  }, {
    path: "/sv/account/tasks",
    component: _17bf654a,
    name: "account-tasks___sv"
  }, {
    path: "/sv/account/team",
    component: _23471612,
    name: "account-team___sv"
  }, {
    path: "/sv/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___sv"
  }, {
    path: "/sv/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___sv"
  }, {
    path: "/sv/auth/forget",
    component: _439c33cf,
    name: "auth-forget___sv"
  }, {
    path: "/sv/auth/login",
    component: _6b3a8677,
    name: "auth-login___sv"
  }, {
    path: "/sv/auth/signup",
    component: _4870357a,
    name: "auth-signup___sv"
  }, {
    path: "/tr/account/activity",
    component: _ab2d74b4,
    name: "account-activity___tr"
  }, {
    path: "/tr/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___tr"
  }, {
    path: "/tr/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___tr"
  }, {
    path: "/tr/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___tr"
  }, {
    path: "/tr/account/faq",
    component: _d8f7e062,
    name: "account-faq___tr"
  }, {
    path: "/tr/account/health",
    component: _1c47ba33,
    name: "account-health___tr"
  }, {
    path: "/tr/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___tr"
  }, {
    path: "/tr/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___tr"
  }, {
    path: "/tr/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___tr"
  }, {
    path: "/tr/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___tr"
  }, {
    path: "/tr/account/presets",
    component: _f5bf9120,
    name: "account-presets___tr"
  }, {
    path: "/tr/account/tasks",
    component: _17bf654a,
    name: "account-tasks___tr"
  }, {
    path: "/tr/account/team",
    component: _23471612,
    name: "account-team___tr"
  }, {
    path: "/tr/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___tr"
  }, {
    path: "/tr/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___tr"
  }, {
    path: "/tr/auth/forget",
    component: _439c33cf,
    name: "auth-forget___tr"
  }, {
    path: "/tr/auth/login",
    component: _6b3a8677,
    name: "auth-login___tr"
  }, {
    path: "/tr/auth/signup",
    component: _4870357a,
    name: "auth-signup___tr"
  }, {
    path: "/zh/account/activity",
    component: _ab2d74b4,
    name: "account-activity___zh"
  }, {
    path: "/zh/account/api-tokens",
    component: _56b24807,
    name: "account-api-tokens___zh"
  }, {
    path: "/zh/account/billing-plan",
    component: _eac5375c,
    name: "account-billing-plan___zh"
  }, {
    path: "/zh/account/change-password",
    component: _49ec4b5e,
    name: "account-change-password___zh"
  }, {
    path: "/zh/account/faq",
    component: _d8f7e062,
    name: "account-faq___zh"
  }, {
    path: "/zh/account/health",
    component: _1c47ba33,
    name: "account-health___zh"
  }, {
    path: "/zh/account/invoices",
    component: _1235b1fd,
    name: "account-invoices___zh"
  }, {
    path: "/zh/account/jobs",
    component: _1c42a8a0,
    name: "account-jobs___zh"
  }, {
    path: "/zh/account/notifications",
    component: _5ebaab61,
    name: "account-notifications___zh"
  }, {
    path: "/zh/account/payment-method",
    component: _c1c0a6bc,
    name: "account-payment-method___zh"
  }, {
    path: "/zh/account/presets",
    component: _f5bf9120,
    name: "account-presets___zh"
  }, {
    path: "/zh/account/tasks",
    component: _17bf654a,
    name: "account-tasks___zh"
  }, {
    path: "/zh/account/team",
    component: _23471612,
    name: "account-team___zh"
  }, {
    path: "/zh/account/webhooks",
    component: _44417d94,
    name: "account-webhooks___zh"
  }, {
    path: "/zh/auth/admin-login",
    component: _a67c760e,
    name: "auth-admin-login___zh"
  }, {
    path: "/zh/auth/forget",
    component: _439c33cf,
    name: "auth-forget___zh"
  }, {
    path: "/zh/auth/login",
    component: _6b3a8677,
    name: "auth-login___zh"
  }, {
    path: "/zh/auth/signup",
    component: _4870357a,
    name: "auth-signup___zh"
  }, {
    path: "/de/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___de"
  }, {
    path: "/de/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___de"
  }, {
    path: "/de/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___de"
  }, {
    path: "/en-br/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___en-br"
  }, {
    path: "/en-br/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___en-br"
  }, {
    path: "/en-br/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___en-br"
  }, {
    path: "/en-id/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___en-id"
  }, {
    path: "/en-id/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___en-id"
  }, {
    path: "/en-id/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___en-id"
  }, {
    path: "/en-in/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___en-in"
  }, {
    path: "/en-in/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___en-in"
  }, {
    path: "/en-in/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___en-in"
  }, {
    path: "/en-my/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___en-my"
  }, {
    path: "/en-my/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___en-my"
  }, {
    path: "/en-my/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___en-my"
  }, {
    path: "/en-ph/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___en-ph"
  }, {
    path: "/en-ph/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___en-ph"
  }, {
    path: "/en-ph/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___en-ph"
  }, {
    path: "/es/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___es"
  }, {
    path: "/es/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___es"
  }, {
    path: "/es/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___es"
  }, {
    path: "/fr/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___fr"
  }, {
    path: "/fr/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___fr"
  }, {
    path: "/fr/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___fr"
  }, {
    path: "/id/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___id"
  }, {
    path: "/id/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___id"
  }, {
    path: "/id/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___id"
  }, {
    path: "/it/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___it"
  }, {
    path: "/it/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___it"
  }, {
    path: "/it/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___it"
  }, {
    path: "/ja/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___ja"
  }, {
    path: "/ja/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___ja"
  }, {
    path: "/ja/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___ja"
  }, {
    path: "/ko/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___ko"
  }, {
    path: "/ko/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___ko"
  }, {
    path: "/ko/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___ko"
  }, {
    path: "/nl/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___nl"
  }, {
    path: "/nl/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___nl"
  }, {
    path: "/nl/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___nl"
  }, {
    path: "/pl/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___pl"
  }, {
    path: "/pl/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___pl"
  }, {
    path: "/pl/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___pl"
  }, {
    path: "/pt/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___pt"
  }, {
    path: "/pt/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___pt"
  }, {
    path: "/pt/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___pt"
  }, {
    path: "/sv/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___sv"
  }, {
    path: "/sv/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___sv"
  }, {
    path: "/sv/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___sv"
  }, {
    path: "/tr/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___tr"
  }, {
    path: "/tr/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___tr"
  }, {
    path: "/tr/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___tr"
  }, {
    path: "/zh/account/payment-method/change-card",
    component: _7ef68946,
    name: "account-payment-method-change-card___zh"
  }, {
    path: "/zh/auth/free/password-set",
    component: _5b7b1201,
    name: "auth-free-password-set___zh"
  }, {
    path: "/zh/auth/free/signup",
    component: _2fb570ae,
    name: "auth-free-signup___zh"
  }, {
    path: "/",
    component: _27eb63ad,
    name: "index___en"
  }, {
    path: "/de/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___de"
  }, {
    path: "/en-br/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___en-br"
  }, {
    path: "/en-id/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___en-id"
  }, {
    path: "/en-in/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___en-in"
  }, {
    path: "/en-my/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___en-my"
  }, {
    path: "/en-ph/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___en-ph"
  }, {
    path: "/es/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___es"
  }, {
    path: "/fr/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___fr"
  }, {
    path: "/id/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___id"
  }, {
    path: "/it/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___it"
  }, {
    path: "/ja/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___ja"
  }, {
    path: "/ko/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___ko"
  }, {
    path: "/nl/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___nl"
  }, {
    path: "/pl/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___pl"
  }, {
    path: "/pt/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___pt"
  }, {
    path: "/sv/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___sv"
  }, {
    path: "/tr/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___tr"
  }, {
    path: "/zh/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___zh"
  }, {
    path: "/account/team/join/:id?",
    component: _5b628dc0,
    name: "account-team-join-id___en"
  }, {
    path: "/de/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___de"
  }, {
    path: "/de/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___de"
  }, {
    path: "/de/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___de"
  }, {
    path: "/en-br/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___en-br"
  }, {
    path: "/en-br/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___en-br"
  }, {
    path: "/en-br/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___en-br"
  }, {
    path: "/en-id/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___en-id"
  }, {
    path: "/en-id/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___en-id"
  }, {
    path: "/en-id/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___en-id"
  }, {
    path: "/en-in/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___en-in"
  }, {
    path: "/en-in/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___en-in"
  }, {
    path: "/en-in/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___en-in"
  }, {
    path: "/en-my/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___en-my"
  }, {
    path: "/en-my/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___en-my"
  }, {
    path: "/en-my/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___en-my"
  }, {
    path: "/en-ph/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___en-ph"
  }, {
    path: "/en-ph/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___en-ph"
  }, {
    path: "/en-ph/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___en-ph"
  }, {
    path: "/es/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___es"
  }, {
    path: "/es/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___es"
  }, {
    path: "/es/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___es"
  }, {
    path: "/fr/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___fr"
  }, {
    path: "/fr/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___fr"
  }, {
    path: "/fr/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___fr"
  }, {
    path: "/id/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___id"
  }, {
    path: "/id/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___id"
  }, {
    path: "/id/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___id"
  }, {
    path: "/it/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___it"
  }, {
    path: "/it/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___it"
  }, {
    path: "/it/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___it"
  }, {
    path: "/ja/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___ja"
  }, {
    path: "/ja/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___ja"
  }, {
    path: "/ja/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___ja"
  }, {
    path: "/ko/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___ko"
  }, {
    path: "/ko/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___ko"
  }, {
    path: "/ko/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___ko"
  }, {
    path: "/nl/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___nl"
  }, {
    path: "/nl/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___nl"
  }, {
    path: "/nl/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___nl"
  }, {
    path: "/pl/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___pl"
  }, {
    path: "/pl/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___pl"
  }, {
    path: "/pl/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___pl"
  }, {
    path: "/pt/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___pt"
  }, {
    path: "/pt/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___pt"
  }, {
    path: "/pt/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___pt"
  }, {
    path: "/sv/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___sv"
  }, {
    path: "/sv/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___sv"
  }, {
    path: "/sv/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___sv"
  }, {
    path: "/tr/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___tr"
  }, {
    path: "/tr/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___tr"
  }, {
    path: "/tr/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___tr"
  }, {
    path: "/zh/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___zh"
  }, {
    path: "/zh/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___zh"
  }, {
    path: "/zh/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___zh"
  }, {
    path: "/account/jobs/:id",
    component: _d8f84bd0,
    name: "account-jobs-id___en"
  }, {
    path: "/account/tasks/:id",
    component: _34cb9b9c,
    name: "account-tasks-id___en"
  }, {
    path: "/auth/reset/:id?",
    component: _2523a888,
    name: "auth-reset-id___en"
  }, {
    path: "/de/convert/:id",
    component: _070f19bc,
    name: "convert-id___de"
  }, {
    path: "/de/time/:id?",
    component: _342c8426,
    name: "time-id___de"
  }, {
    path: "/de/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___de"
  }, {
    path: "/en-br/convert/:id",
    component: _070f19bc,
    name: "convert-id___en-br"
  }, {
    path: "/en-br/time/:id?",
    component: _342c8426,
    name: "time-id___en-br"
  }, {
    path: "/en-br/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___en-br"
  }, {
    path: "/en-id/convert/:id",
    component: _070f19bc,
    name: "convert-id___en-id"
  }, {
    path: "/en-id/time/:id?",
    component: _342c8426,
    name: "time-id___en-id"
  }, {
    path: "/en-id/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___en-id"
  }, {
    path: "/en-in/convert/:id",
    component: _070f19bc,
    name: "convert-id___en-in"
  }, {
    path: "/en-in/time/:id?",
    component: _342c8426,
    name: "time-id___en-in"
  }, {
    path: "/en-in/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___en-in"
  }, {
    path: "/en-my/convert/:id",
    component: _070f19bc,
    name: "convert-id___en-my"
  }, {
    path: "/en-my/time/:id?",
    component: _342c8426,
    name: "time-id___en-my"
  }, {
    path: "/en-my/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___en-my"
  }, {
    path: "/en-ph/convert/:id",
    component: _070f19bc,
    name: "convert-id___en-ph"
  }, {
    path: "/en-ph/time/:id?",
    component: _342c8426,
    name: "time-id___en-ph"
  }, {
    path: "/en-ph/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___en-ph"
  }, {
    path: "/es/convert/:id",
    component: _070f19bc,
    name: "convert-id___es"
  }, {
    path: "/es/time/:id?",
    component: _342c8426,
    name: "time-id___es"
  }, {
    path: "/es/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___es"
  }, {
    path: "/fr/convert/:id",
    component: _070f19bc,
    name: "convert-id___fr"
  }, {
    path: "/fr/time/:id?",
    component: _342c8426,
    name: "time-id___fr"
  }, {
    path: "/fr/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___fr"
  }, {
    path: "/id/convert/:id",
    component: _070f19bc,
    name: "convert-id___id"
  }, {
    path: "/id/time/:id?",
    component: _342c8426,
    name: "time-id___id"
  }, {
    path: "/id/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___id"
  }, {
    path: "/it/convert/:id",
    component: _070f19bc,
    name: "convert-id___it"
  }, {
    path: "/it/time/:id?",
    component: _342c8426,
    name: "time-id___it"
  }, {
    path: "/it/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___it"
  }, {
    path: "/ja/convert/:id",
    component: _070f19bc,
    name: "convert-id___ja"
  }, {
    path: "/ja/time/:id?",
    component: _342c8426,
    name: "time-id___ja"
  }, {
    path: "/ja/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___ja"
  }, {
    path: "/ko/convert/:id",
    component: _070f19bc,
    name: "convert-id___ko"
  }, {
    path: "/ko/time/:id?",
    component: _342c8426,
    name: "time-id___ko"
  }, {
    path: "/ko/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___ko"
  }, {
    path: "/nl/convert/:id",
    component: _070f19bc,
    name: "convert-id___nl"
  }, {
    path: "/nl/time/:id?",
    component: _342c8426,
    name: "time-id___nl"
  }, {
    path: "/nl/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___nl"
  }, {
    path: "/pl/convert/:id",
    component: _070f19bc,
    name: "convert-id___pl"
  }, {
    path: "/pl/time/:id?",
    component: _342c8426,
    name: "time-id___pl"
  }, {
    path: "/pl/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___pl"
  }, {
    path: "/pt/convert/:id",
    component: _070f19bc,
    name: "convert-id___pt"
  }, {
    path: "/pt/time/:id?",
    component: _342c8426,
    name: "time-id___pt"
  }, {
    path: "/pt/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___pt"
  }, {
    path: "/sv/convert/:id",
    component: _070f19bc,
    name: "convert-id___sv"
  }, {
    path: "/sv/time/:id?",
    component: _342c8426,
    name: "time-id___sv"
  }, {
    path: "/sv/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___sv"
  }, {
    path: "/tr/convert/:id",
    component: _070f19bc,
    name: "convert-id___tr"
  }, {
    path: "/tr/time/:id?",
    component: _342c8426,
    name: "time-id___tr"
  }, {
    path: "/tr/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___tr"
  }, {
    path: "/zh/convert/:id",
    component: _070f19bc,
    name: "convert-id___zh"
  }, {
    path: "/zh/time/:id?",
    component: _342c8426,
    name: "time-id___zh"
  }, {
    path: "/zh/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___zh"
  }, {
    path: "/de/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___de"
  }, {
    path: "/en-br/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___en-br"
  }, {
    path: "/en-id/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___en-id"
  }, {
    path: "/en-in/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___en-in"
  }, {
    path: "/en-my/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___en-my"
  }, {
    path: "/en-ph/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___en-ph"
  }, {
    path: "/es/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___es"
  }, {
    path: "/fr/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___fr"
  }, {
    path: "/id/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___id"
  }, {
    path: "/it/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___it"
  }, {
    path: "/ja/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___ja"
  }, {
    path: "/ko/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___ko"
  }, {
    path: "/nl/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___nl"
  }, {
    path: "/pl/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___pl"
  }, {
    path: "/pt/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___pt"
  }, {
    path: "/sv/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___sv"
  }, {
    path: "/tr/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___tr"
  }, {
    path: "/zh/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___zh"
  }, {
    path: "/convert/:id",
    component: _070f19bc,
    name: "convert-id___en"
  }, {
    path: "/de/:id",
    component: _2de0e018,
    name: "id___de"
  }, {
    path: "/en-br/:id",
    component: _2de0e018,
    name: "id___en-br"
  }, {
    path: "/en-id/:id",
    component: _2de0e018,
    name: "id___en-id"
  }, {
    path: "/en-in/:id",
    component: _2de0e018,
    name: "id___en-in"
  }, {
    path: "/en-my/:id",
    component: _2de0e018,
    name: "id___en-my"
  }, {
    path: "/en-ph/:id",
    component: _2de0e018,
    name: "id___en-ph"
  }, {
    path: "/es/:id",
    component: _2de0e018,
    name: "id___es"
  }, {
    path: "/fr/:id",
    component: _2de0e018,
    name: "id___fr"
  }, {
    path: "/id/:id",
    component: _2de0e018,
    name: "id___id"
  }, {
    path: "/it/:id",
    component: _2de0e018,
    name: "id___it"
  }, {
    path: "/ja/:id",
    component: _2de0e018,
    name: "id___ja"
  }, {
    path: "/ko/:id",
    component: _2de0e018,
    name: "id___ko"
  }, {
    path: "/nl/:id",
    component: _2de0e018,
    name: "id___nl"
  }, {
    path: "/pl/:id",
    component: _2de0e018,
    name: "id___pl"
  }, {
    path: "/pt/:id",
    component: _2de0e018,
    name: "id___pt"
  }, {
    path: "/sv/:id",
    component: _2de0e018,
    name: "id___sv"
  }, {
    path: "/time/:id?",
    component: _342c8426,
    name: "time-id___en"
  }, {
    path: "/tr/:id",
    component: _2de0e018,
    name: "id___tr"
  }, {
    path: "/unit/:id?",
    component: _9620c4b8,
    name: "unit-id___en"
  }, {
    path: "/zh/:id",
    component: _2de0e018,
    name: "id___zh"
  }, {
    path: "/convert/:id?/download",
    component: _b0d432e4,
    name: "convert-id-download___en"
  }, {
    path: "/de/:id/download",
    component: _1d5032b2,
    name: "id-download___de"
  }, {
    path: "/en-br/:id/download",
    component: _1d5032b2,
    name: "id-download___en-br"
  }, {
    path: "/en-id/:id/download",
    component: _1d5032b2,
    name: "id-download___en-id"
  }, {
    path: "/en-in/:id/download",
    component: _1d5032b2,
    name: "id-download___en-in"
  }, {
    path: "/en-my/:id/download",
    component: _1d5032b2,
    name: "id-download___en-my"
  }, {
    path: "/en-ph/:id/download",
    component: _1d5032b2,
    name: "id-download___en-ph"
  }, {
    path: "/es/:id/download",
    component: _1d5032b2,
    name: "id-download___es"
  }, {
    path: "/fr/:id/download",
    component: _1d5032b2,
    name: "id-download___fr"
  }, {
    path: "/id/:id/download",
    component: _1d5032b2,
    name: "id-download___id"
  }, {
    path: "/it/:id/download",
    component: _1d5032b2,
    name: "id-download___it"
  }, {
    path: "/ja/:id/download",
    component: _1d5032b2,
    name: "id-download___ja"
  }, {
    path: "/ko/:id/download",
    component: _1d5032b2,
    name: "id-download___ko"
  }, {
    path: "/nl/:id/download",
    component: _1d5032b2,
    name: "id-download___nl"
  }, {
    path: "/pl/:id/download",
    component: _1d5032b2,
    name: "id-download___pl"
  }, {
    path: "/pt/:id/download",
    component: _1d5032b2,
    name: "id-download___pt"
  }, {
    path: "/sv/:id/download",
    component: _1d5032b2,
    name: "id-download___sv"
  }, {
    path: "/tr/:id/download",
    component: _1d5032b2,
    name: "id-download___tr"
  }, {
    path: "/zh/:id/download",
    component: _1d5032b2,
    name: "id-download___zh"
  }, {
    path: "/:id",
    component: _2de0e018,
    name: "id___en"
  }, {
    path: "/:id/download",
    component: _1d5032b2,
    name: "id-download___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
